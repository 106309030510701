.action-slider{
    position: absolute;
    right: 0;
    height: 100%;
    bottom: 0;
    top: 0;
    background:  #f55e29;
    transition: 0.5s;
    width: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    color: #fff;
    font-size: 26px;
    white-space: nowrap;
}
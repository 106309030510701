.main-menu-container{
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
}

.main-menu-container.open{
    opacity: 1;
    z-index: 9999999;
}

.main-menu-container .list-items{
    
    padding: 0;
}

.main-menu-container .list-items li{
    
    font-size: 25px;
    font-weight: 700;
    transition: 0.5s;
    cursor: pointer;
}

.list-items li a{
    padding: 8px 0;
    display: block;
    transition: 0.5s;
    width: 100%;
    color: #ff296d;
}

.list-items li:hover a{
    background-color: aliceblue;
    padding: 8px 6px;
    transform: scale(1.05);
    border-radius: 4px;
}

.list-items li:hover a{
    color: #262626;
}

@media (max-width: 576px) {
    .main-menu-container .wallet-address{
        width: calc(100vw - 130px);
    }
}

.main-menu-container .user-details{
    display: flex;
    align-items: center;
    column-gap: 16px;
}

@media (max-width: 600px) {

    .main-menu-container .list-items li{
        font-size: 20px;
    }
}

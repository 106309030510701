.react-datepicker__input-container > input {
    border: 1px solid #b5b5b5;
    min-height: 40px;
    width: 100%;
    padding: 10px 10px;
    font-size: 16px;
    border-radius: 5px;
}

.react-date-picker {
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    min-height: 40px;
    width: 100%;
}

.react-date-picker__wrapper {
    padding: 0px 10px;
    border: none !important;
}

.react-calendar__tile--now {
    background-color: #f8531f91;
    transition: background-color 0.3s ease;
}

.react-calendar__tile--now:hover {
    background-color: #f8531f;
    cursor: pointer;
}